import { NO_VALIDATOR_FOR_THIS_FILED, RENTAL_APPLICATION_NOT_FOUND } from '~/assets/strings';
import { useLocalization } from '~/hooks/useLocalization';
import { StepError } from '~/state/mainAppState';
import { RentalApplicationErrorCode } from '~/types/RentalApplication';
import { RentalApplicationSteps } from '~/types/RentalApplicationSteps';
import { MagicUseCase } from '~/use-cases/magicUseCase';

export class ValidateTermsAndConditions extends MagicUseCase {
  private validateHasAcceptedMagicDoorEULA = () => {
    if (this.getState().user.rentalApplication.application?.hasAcceptedMagicDoorTerms !== true) {
      return RentalApplicationErrorCode.InvalidMagicDoorAgreement;
    }
  };

  private validateHasAcceptedTransUnionEULA = () => {
    const { application, settings } = this.getState().user.rentalApplication;

    if (settings?.requiresScreening && application?.hasAcceptedTransUnionTerms !== true) {
      return RentalApplicationErrorCode.InvalidTransUnionAgreement;
    }
  };

  private rules: Partial<Record<string, { validator: () => RentalApplicationErrorCode | undefined }>> = {
    magicDoorLicense: { validator: this.validateHasAcceptedMagicDoorEULA },
    transUnionLicense: { validator: this.validateHasAcceptedTransUnionEULA },
  };

  protected async runLogic(fieldName?: string) {
    const { t } = useLocalization();
    const application = this.getState().user.rentalApplication.application;

    if (!application) {
      throw new Error(t(RENTAL_APPLICATION_NOT_FOUND));
    }

    this.getState().user.rentalApplication.errors = { ...this.getState().user.rentalApplication.errors };

    if (fieldName) {
      this.getState().user.rentalApplication.errors[RentalApplicationSteps.TERMS_AND_CONDITIONS] = {
        ...this.getState().user.rentalApplication.errors[RentalApplicationSteps.TERMS_AND_CONDITIONS],
      };

      const validator = this.rules[fieldName]?.validator;
      const stepError = this.getState().user.rentalApplication.errors[RentalApplicationSteps.TERMS_AND_CONDITIONS] as StepError;

      if (!validator) {
        throw new Error(t(NO_VALIDATOR_FOR_THIS_FILED, { fieldName }));
      }

      stepError[fieldName] = validator();
    } else {
      this.getState().user.rentalApplication.errors[RentalApplicationSteps.TERMS_AND_CONDITIONS] = {};

      Object.keys(this.rules).forEach((key) => {
        const stepError = this.getState().user.rentalApplication.errors[RentalApplicationSteps.TERMS_AND_CONDITIONS] as StepError;
        stepError[key] = this.rules[key]?.validator();
      });
    }
  }
}
